import { invoiceTypes } from 'common/enums/invoice';

import { sumPropInArray, sumArray } from './arrayHelpers';

// use for form
// sum without commission, fee, discount, reward
export const getAmountCharge = ({
  invoice_type,
  amount_tip,
  deposit,
  labor,
  material,
  amount_deposit,
  amount_labor_cost,
  amount_material
}) => {
  if (invoice_type === invoiceTypes.DEPOSIT.value) {
    return deposit?.length ? sumPropInArray(deposit) : amount_deposit;
  }
  const laborTotal = labor?.length ? sumPropInArray(labor) : amount_labor_cost;
  const materialTotal = material?.length ? sumPropInArray(material) : amount_material;
  return sumArray([laborTotal, materialTotal, amount_tip]);
};

const getAmountChargeWithDiscount = ({
  invoice_type,
  amount_customer_reward,
  reward,
  amount_tip,
  deposit,
  labor,
  material,
  amount_deposit,
  amount_labor_cost,
  amount_material
}) => {
  const amount_reward = reward?.id ? reward?.amount / 100 : 0;
  const discount = parseFloat(amount_customer_reward || 0) || amount_reward;
  const amount_charge = parseFloat(
    getAmountCharge({
      invoice_type,
      amount_tip,
      deposit,
      labor,
      material,
      amount_deposit,
      amount_labor_cost,
      amount_material
    }) || 0
  );

  if (!discount) {
    return String(amount_charge);
  }
  if (amount_charge < discount) {
    return '0';
  }
  return String(amount_charge - discount);
};

export const getAmountStripeFee = ({
  percent_stripe_fee,
  invoice_type,
  amount_tip,
  deposit,
  labor,
  material,
  amount_deposit,
  amount_labor_cost,
  amount_material
}) => {
  const amount = parseFloat(
    getAmountCharge({
      invoice_type,
      amount_tip,
      deposit,
      labor,
      material,
      amount_deposit,
      amount_labor_cost,
      amount_material
    }) || 0
  );

  return String((amount * parseFloat(percent_stripe_fee || 0)) / 100);
};

export const getTotalPrice = ({
  percent_stripe_fee,
  invoice_type,
  amount_customer_reward,
  reward,
  amount_tip,
  deposit,
  labor,
  material,
  amount_deposit,
  amount_labor_cost,
  amount_material
}) => {
  const amount = parseFloat(
    getAmountCharge({
      invoice_type,
      amount_tip,
      deposit,
      labor,
      material,
      amount_deposit,
      amount_labor_cost,
      amount_material
    }) || 0
  );

  const amountWithDiscount = parseFloat(
    getAmountChargeWithDiscount({
      invoice_type,
      amount_customer_reward,
      reward,
      amount_tip,
      deposit,
      labor,
      material,
      amount_deposit,
      amount_labor_cost,
      amount_material
    }) || 0
  );
  const amountStripeFee = (amount * parseFloat(percent_stripe_fee || 0)) / 100;

  return String(amountWithDiscount + amountStripeFee);
};

export const getPayoutSum = ({
  invoice_type,
  amount_tip,
  deposit,
  labor,
  material,
  amount_deposit,
  amount_labor_cost,
  amount_material,
  percent_commission,
  commission_debt
}) => {
  const isDeposit = invoice_type === invoiceTypes.DEPOSIT.value;
  const commissionDebt = commission_debt ? commission_debt / 100 : 0;
  const amount_charge = getAmountCharge({
    invoice_type,
    amount_tip,
    deposit,
    labor,
    material,
    amount_deposit,
    amount_labor_cost,
    amount_material
  });
  const tipAmount = parseFloat(amount_tip) || 0;
  // For deposits no Dobby Fee (commission) is retracted
  if (isDeposit) return amount_charge;
  const payoutSum = (amount_charge - tipAmount) * (1 - percent_commission / 100) + tipAmount;
  const payoutDebt = commissionDebt > payoutSum ? payoutSum : commissionDebt;
  return payoutSum - payoutDebt;
};

export const getFee = ({
  invoice_type,
  amount_customer_reward,
  reward,
  amount_tip,
  deposit,
  labor,
  material,
  amount_deposit,
  amount_labor_cost,
  amount_material,
  percent_commission,
  commission_debt
}) => {
  const isDeposit = invoice_type === invoiceTypes.DEPOSIT.value;
  const amount_reward = reward?.id ? reward?.amount / 100 : 0;
  const discount = parseFloat(amount_customer_reward || 0) || amount_reward;
  const commissionDebt = commission_debt ? commission_debt / 100 : 0;
  const amount_charge = getAmountCharge({
    invoice_type,
    amount_tip,
    deposit,
    labor,
    material,
    amount_deposit,
    amount_labor_cost,
    amount_material
  });
  const tipAmount = parseFloat(amount_tip) || 0;
  // For deposits no Dobby Fee (commission) is retracted
  // but discounts in deposit still apply
  if (isDeposit) return discount <= amount_charge ? 0 - discount : 0 - amount_charge;
  const commission = (amount_charge - tipAmount) * (percent_commission / 100);
  const debt = commissionDebt + commission >= amount_charge ? amount_charge - commission : commissionDebt;
  return commission + debt - discount;
};

export default {
  getAmountCharge,
  getAmountChargeWithDiscount,
  getAmountStripeFee,
  getTotalPrice,
  getPayoutSum,
  getFee
};
