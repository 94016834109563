import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getCustomerDobbyCashTransactions({ payload }) {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.DOBBY_CASH_TRANSACTIONS}/?user_id=${payload}`);
    console.log('getCustomerDobbyCashTransactions response', response);
    yield put({
      type: actionTypes.GET_CUSTOMER_DOBBY_CASH_TRANSACTIONS_SUCCESS,
      payload: response.results
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_CUSTOMER_DOBBY_CASH_TRANSACTIONS_ERROR
    });
  }
}

export default function* watchGetCustomerDobbyCashTransactions() {
  yield takeLatest(actionTypes.GET_CUSTOMER_DOBBY_CASH_TRANSACTIONS_FETCH, getCustomerDobbyCashTransactions);
}
