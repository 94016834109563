import { AsYouType } from 'libphonenumber-js';
import moment from 'moment-timezone';

import { formatters } from 'utils';
import { operationalAreaIDs } from 'common/enums/location';
import { getFullName } from 'common/helpers/user';
import dates from 'constants/dates';
import styles from 'constants/styles';
import { earlyWarnings } from 'constants/content';

export const utcOffset = (datetime) => moment(datetime).utcOffset();

export const formatPhone = (value) => (value ? new AsYouType('US').input(value) : value);

export const numberWithCommas = (x) => (x < 0 ? -x : x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const URL_PATTERN = new RegExp(
  // eslint-disable-next-line
  /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*[-a-zA-Z0-9@:%_\+~#?&\/=])*/,
  'gi'
);

export const parseLinks = (text) => {
  const linkMatches = text?.match(URL_PATTERN) || [];
  const links = linkMatches
    ? linkMatches.map((link, idx) => ({
        id: idx + 1,
        preview_url: link
      }))
    : [];
  return links;
};

export const chatMessageTransformer = ({
  id,
  action,
  author_name,
  message,
  direction,
  date_created,
  author_id,
  action_data,
  image_url,
  ...data
}) => {
  let text = '';
  if (action === 'schedule_visit') {
    text = `📅 ${author_name || (author_id ? `User # ${author_id}` : 'Dobby')} proposed time${
      action_data.length === 1 ? '' : 's'
    } for the visit`;
  } else if (message) {
    text = message;
  } else if (action) {
    text = image_url ? ' ' : "The message can't be displayed";
  }

  return {
    ...data,
    action: action,
    action_data: action_data,
    author_id: author_id,
    author_name: author_name,
    _id: `${id}`,
    text,
    user: {
      _id: author_id === null ? 'support' : author_id
    },
    image: image_url,
    createdAt: moment(date_created).add(utcOffset(date_created), 'minutes').format(dates.DATETIME_ISO),
    link_list: parseLinks(message),
    link_list_loaded: 0
  };
};

export const smsMessageTransformer = ({ id, message, direction, displayed_sender, date_created, ...data }) => ({
  ...data,
  author_id: null,
  author_name: direction == 'in' ? displayed_sender : 'Dobby',
  _id: `${id}`,
  text: message,
  user: {
    _id: direction == 'in' ? displayed_sender : 'support'
  },
  createdAt: moment(date_created).add(utcOffset(date_created), 'minutes').format(dates.DATETIME_ISO)
});

export const getWarningNameById = (id) => earlyWarnings.find((warning) => warning.id === id)?.name || '';

export const getWarningDescriptionById = (id, value) => {
  const description = earlyWarnings.find((warning) => warning.id === id)?.description || null;
  let dayAmount = null;
  if (value !== null) {
    if (value === 0) {
      if (id === 0) {
        dayAmount = 'more than 2.5 hours';
      } else {
        dayAmount = 'less than 1 day';
      }
    } else {
      dayAmount = value + ' days';
    }
  }
  return description && dayAmount ? dayAmount + ' ' + description : null;
};

export const getWarningColorById = (id) =>
  earlyWarnings.find((warning) => warning.id === id)?.color || styles.colors.BLACK;

export const formatDateStamp = (datetimeStamp) => {
  return datetimeStamp && moment(datetimeStamp).add(utcOffset(datetimeStamp), 'minutes').format(dates.DATE);
};

export const formatDateRange = (firstDatetimeStamp, secondDatetimeStamp, utc_offset) => {
  if (!firstDatetimeStamp || !secondDatetimeStamp) {
    return [firstDatetimeStamp, secondDatetimeStamp];
  }

  return [
    moment(firstDatetimeStamp)
      .subtract(utc_offset || utcOffset(firstDatetimeStamp), 'minutes')
      .format(dates.DATETIME_ISO),
    moment(secondDatetimeStamp)
      .add(1, 'days')
      .subtract(utc_offset || utcOffset(secondDatetimeStamp), 'minutes')
      .format(dates.DATETIME_ISO)
  ];
};

export const formatDateShortTimeStamp = (datetimeStamp) => {
  return moment(datetimeStamp).add(utcOffset(datetimeStamp), 'minutes').format(dates.DATETIME_FULL_YEAR_A);
};

export const formatDateTimeStamp = (datetimeStamp) => {
  return moment(datetimeStamp).add(utcOffset(datetimeStamp), 'minutes').format(dates.DATETIME_FULL_YEAR_A_2);
};

export const formatUTCDateTimeStamp = (datetimeStamp) => {
  return moment.utc(datetimeStamp).format(dates.DATETIME_FULL_YEAR_A_2);
};

export const formatLocalDateTimeStamp = (datetimeStamp) => {
  return moment(datetimeStamp).format(dates.DATETIME_FULL_YEAR_A_2);
};

export const formatDateShortTimeStampSmall = (datetimeStamp) => {
  return moment(datetimeStamp).add(utcOffset(datetimeStamp), 'minutes').format(dates.DATETIME_FULL_YEAR);
};

export const isDateTimeAfter = ({ datetime, duration = 5, measure = 'minutes' }) =>
  moment(datetime).add(utcOffset(datetime), 'minutes').isAfter(moment().subtract(duration, measure));

export const getDaysCountFromDate = (datetime) => {
  return moment().diff(moment(datetime).add(utcOffset(datetime), 'minutes'), 'days');
};

export const getHumanizedDuration = (datetime) => {
  const localDate = moment(datetime).add(utcOffset(datetime), 'minutes');
  const duration = moment.duration(localDate.diff());
  return duration.humanize(true);
};

export const placeCommas = (string) => string.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const convertAmountValueToString = (value) => ((value || 0) / 100).toString();

export const convertNumberToString = (value) => (value || 0).toString();

export const convertStringToAmountValue = (value) => (Number(value.replace(/,/g, '')) || 0) * 100;

export const convertStringToNumber = (value) => Number(value.replace(/,/g, '')) || 0;

export const renderSystemMessage = ({ id, message, date_created }) => ({
  _id: `${id}`,
  text: message,
  system: true,
  createdAt: date_created
});

export const openNestedAvailable = ({ pathname, hasGoBackButton, level = 4 }) =>
  !hasGoBackButton && (pathname.split('/') || []).length <= level;

export const getFormattedReview = ({
  id,
  date_created,
  service_demand_id,
  media,
  rating,
  user,
  provider,
  comment,
  isCustomers
}) => ({
  id,
  type: rating === 5 ? 'positive' : 'negative',
  title: isCustomers ? getFullName(provider) : getFullName(user),
  pro_stage: isCustomers ? provider?.pro_stage : '',
  time: formatters.humanizedDate(date_created),
  service_demand_id,
  comment,
  imageUrl: media?.thumbnail_path
});

export const getUserIcon = ({ is_cognito_active, is_api_active, is_active, is_pre_filled }) => {
  if (is_pre_filled) {
    // pre filled (not active) user
    return {
      name: 'ok',
      color: styles.colors.BLACK,
      backgroundColor: styles.colors.LIGHT_GRAY_2,
      sizeCoeff: 0.8
    };
  }
  if (!is_cognito_active) {
    // removed user
    return {
      name: 'close2',
      color: styles.colors.BLACK,
      backgroundColor: styles.colors.LIGHT_GRAY_2,
      sizeCoeff: 0.5
    };
  }
  if (!is_api_active) {
    // blocked user
    return {
      name: 'blocked',
      color: styles.colors.WHITE,
      backgroundColor: styles.colors.RED,
      sizeCoeff: 0.6
    };
  }
  if (!is_active) {
    // not active user
    return {
      name: 'ok',
      color: styles.colors.BLACK,
      backgroundColor: styles.colors.LIGHT_GRAY_2,
      sizeCoeff: 0.8
    };
  }
  // active user
  return {
    name: 'ok',
    color: styles.colors.WHITE,
    backgroundColor: styles.colors.GREEN,
    sizeCoeff: 0.8
  };
};

export const getOperationalAreaNameByID = (operationalAreaId) => {
  const { DCMA, CHL, SFBA } = operationalAreaIDs;
  switch (String(operationalAreaId)) {
    case DCMA.TYPE:
      return DCMA.NAME;
    case CHL.TYPE:
      return CHL.NAME;
    case SFBA.TYPE:
      return SFBA.NAME;
    case operationalAreaIDs.null.TYPE:
      return operationalAreaIDs.null.NAME;
    default:
      return '-';
  }
};

export const clipTooltipText = (text, ...sliceIndexes) =>
  text
    ?.split(' ')
    .slice(...sliceIndexes)
    .join(' ');

/**
 * Helper function for truncating to specified item limit & transforming items for provided string array
 * @param {number} itemIdx - index of item to process
 * @param {number} itemLimit - max number of items before truncation
 * @param {any} item - Object/string/number to pass to transformer function
 * @param {Array} itemArray - array of items that are processed
 * @param {Function} transformer - function to process items with
 * @returns {string || any} the item processed with transform function or truncated
 */
export const truncateTextItemsWithTransformation = (
  itemIdx,
  itemLimit,
  item,
  itemArray,
  transformer = (item) => item
) =>
  itemIdx >= itemLimit
    ? '' // ignore all items after limit
    : itemIdx === itemArray.length - 1 && itemIdx < itemLimit
    ? transformer(item) // last item if limit isn't reached
    : itemIdx === itemLimit - 1
    ? `${transformer(item)}...` // last item if limit's reached
    : `${transformer(item)}, `; // concat items before last one

/**
 * Helper function for truncating to specified text length & transforming items for provided string array
 * @param {Array} itemArray - array of items that are processed
 * @param {number} stringLimit - max length of items string before truncation
 * @param {Function} transformer - function to process items with
 * @returns {string} the item processed with transform function or truncated
 */
export const truncateTextLengthWithTransformation = (itemArray, stringLimit, transformer = (item) => item) => {
  const transformedString = itemArray.map((item) => transformer(item)).join(', ');
  return transformedString.length <= stringLimit
    ? transformedString
    : `${transformedString.slice(0, stringLimit - 3)}...`;
};

/**
 * Compares two equally structured arrays of objects and returns an array of different objects
 * @param {Array} array1 - array to compare against
 * @param {Array} array2 - array to compare with
 * @param {any} compareField - property used to compare objects
 * @returns {Array} - contains objects which are in first array and not in second array
 */
export const findArraysDiff = (array1, array2, compareField = 'id') =>
  array1.filter(
    (array1Element) => !array2.some((array2Element) => array1Element?.[compareField] === array2Element?.[compareField])
  );
