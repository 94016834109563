import styled from 'styled-components';
import styles from 'constants/styles';
import ReactMarkdown from 'react-markdown';
import 'react-image-lightbox/style.css';

export const ProgressHeader = styled.p`
  margin: 24px 0 20px 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const ProgressStack = styled.div`
  margin-left: 6px;
`;

export const ProgressAction = styled.div`
  border-left: ${({ isLast }) => `1px solid ${isLast ? 'transparent' : styles.colors.DARK_GREY_3}`};
  margin: 0;
  padding: 0;

  &:before {
    display: flex;
    content: '';
  }
`;

export const ProgressPointer = styled.div`
  height: 11px;
  width: 11px;
  border-radius: 6px;
  margin: -4px -12px -12px 0;

  position: relative;
  left: -6px;
  top: 4px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.DARK_GREEN : theme.colors.DARK_GREY_3)};
`;

export const ProgressData = styled.div`
  display: flex;
  flex-direction: column;
  color: ${styles.colors.GRAY};
  padding: 0 0 25px 20px;
`;

export const ProgressTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const ProgressTitle = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 1.53;

  color: ${({ isActive, isYellow, theme, color }) => {
    if (!isActive) {
      return color || theme.colors.DARK_GREY;
    }
    return isYellow ? theme.colors.INVOICE_TITLE_NOTE : color || theme.colors.DARK_GREEN;
  }};
  ${({ theme, future }) => future && `color: ${theme.colors.DARK_GREY_3}`};
`;

export const ProgressTitleNote = styled(ProgressTitle)`
  color: ${({ isActive, theme }) => (isActive ? theme.colors.INVOICE_TITLE_NOTE : theme.colors.DARK_GREY)};
  padding-left: 8px;
`;

export const ProgressCreated = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0 0 1px 8px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const ProgressDescription = styled(ReactMarkdown)`
  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.31;
    margin: 2px 0 0 0;
    white-space: pre-wrap;
    color: ${({ isActive, theme, color }) => (color || isActive ? theme.colors.INVOICE_TEXT : theme.colors.DARK_GREY)};
  }
`;

export const ProgressSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 24px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 50px;
  justify-content: center;
  position: relative;
  height: calc(100vh - ${styles.layout.headerHeight}px);
`;

export const ReviewAttachment = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.3;
  color: ${styles.colors.WHITE};
  text-decoration: underline;
  margin-top: 10px;
  &:hover {
    color: ${styles.colors.DARK_GREEN};
  }
`;

export const ReviewAttachmentIconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
