import React from 'react';

import { useCallback, useEffect } from 'hooks';
import { fieldTypes } from 'common/enums/form';
import { commissionTypes } from 'common/enums/invoice';
import { HookForm } from 'components';
import { useInvoiceTotalValues } from '../../hooks/useInvoiceTotalValues';
import { calculatePlatformFeePercent } from '../../../../helpers/invoice';

import { Column, Row, Subtitle, FieldName } from './styled';
import { inputWrapperStyle } from '../../commonStyled';

export const InvoiceFee = ({
  commissionType,
  control,
  hasStaticCommissionPercent,
  invoice,
  invoiceType,
  partialEditing,
  setValue,
  touchedFields,
  task
}) => {
  const { payoutSum, invoiceValues } = useInvoiceTotalValues({
    commissionDebt: invoice?.commission_debt || task.current_commission_debt,
    commissionType,
    control,
    invoice,
    invoiceType
  });

  const handlePayoutChange = useCallback(() => {
    if (hasStaticCommissionPercent || touchedFields['percent_commission']) {
      return;
    }
    const commissionPercent = calculatePlatformFeePercent({ providerPayout: payoutSum });
    if (invoiceValues.percent_commission !== commissionPercent) {
      setValue('percent_commission', commissionPercent, { shouldTouch: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutSum, invoiceValues.percent_commission, touchedFields]);

  useEffect(() => {
    handlePayoutChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutSum]);

  return (
    <>
      <Column>
        <Row>
          <Column>
            <FieldName>Dobby’s Operating Margin %*</FieldName>
            {commissionType === commissionTypes.SUB_FEE ? (
              <Subtitle>(Will be deducted automatically from Labor+Materials)</Subtitle>
            ) : null}
          </Column>
          <HookForm.Input
            name="percent_commission"
            control={control}
            fieldType={fieldTypes.PERCENT}
            readOnly={partialEditing}
            rules={{ required: true }}
            wrapperStyle={inputWrapperStyle}
          />
        </Row>
      </Column>
      <Row>
        <FieldName>Stripe Fee %*</FieldName>
        <HookForm.Input
          name="percent_stripe_fee"
          control={control}
          fieldType={fieldTypes.PERCENT}
          rules={{ required: true }}
          wrapperStyle={inputWrapperStyle}
        />
      </Row>
    </>
  );
};
