import React from 'react';
import { MediaUploader, HookForm, PopoverColorPicker } from 'components';
import { useSelector } from 'hooks';

import { selectProviderAreaList } from 'store/reducers/provider';
import { generateServiceAreaListOptions } from 'common/helpers/user';
import { fieldTypes } from 'common/enums/form';

import { ButtonCTA } from './components';
import { CustomFieldsTooltip } from '../';
import { allInstallBase } from 'common/enums/campaigns';

export const ArticleSetUp = ({
  control,
  register,
  bannerPictureState,
  postPictureState,
  values,
  onSetBackgroundColor,
  onSetButtonHidden,
  errors,
  setValue,
  scrollingContainer
}) => {
  const areaList = useSelector(selectProviderAreaList);

  const bannerAreaList = [...generateServiceAreaListOptions(areaList), allInstallBase];

  const handleAreaChange = (selectedOptions) => {
    const allInstallBaseOption = selectedOptions?.find((option) => option?.id === allInstallBase.id);
    const currentValue = values.article.service_area_id_list;
    const wasAllInstallBaseSelected = currentValue?.some((option) => option?.id === allInstallBase.id);

    if (wasAllInstallBaseSelected) {
      setValue(
        'article.service_area_id_list',
        selectedOptions?.filter((option) => option.id !== allInstallBase.id)
      );
    } else {
      if (allInstallBaseOption) {
        setValue('article.service_area_id_list', [allInstallBaseOption]);
      } else {
        setValue('article.service_area_id_list', selectedOptions);
      }
    }
  };

  return (
    <>
      {/* <div style={{ margin: '18px 0 12px 0' }}>
        <HookForm.Select
          label="Location*"
          name="location"
          fill
          rules={{ required: true }}
          control={control}
          placeholder="Select location"
          options={[{ name: 'Banner', id: 'Banner' }]}
        />
      </div>
      <div style={{ margin: '12px 0 0 0' }}>
        <HookForm.Radio
          label={'Order priority in the list'}
          name="order"
          disabled
          control={control}
          style={{ flexDirection: 'row', gap: '42px' }}
          options={[
            { name: 'First', value: 'first' },
            { name: 'Last', value: 'last' }
          ]}
        />
      </div> */}
      <div
        style={{
          fontSize: '20px',
          lineHeight: '24px',
          fontWeight: 400,
          margin: '24px 0 16px'
        }}>
        Banner
      </div>
      <HookForm.SelectMultiple
        name="article.service_area_id_list"
        control={control}
        marginLeft={0}
        label="Area to Display*"
        placeholder="Select working area"
        wrapperStyle={{ marginLeft: 0 }}
        options={bannerAreaList}
        onChange={handleAreaChange}
      />
      <div
        style={{
          margin: '16px 0 5px'
        }}>
        <HookForm.ControlledTextarea
          name="article.title"
          control={control}
          label="Banner Title*"
          placeholder="Enter Title"
          renderTooltip={() => <CustomFieldsTooltip />}
          lengthInfoPrefix="Restricted length"
          rules={{
            maxLength: 59
          }}
          hasSmallLegthTitle
          style={{
            height: '35px',
            minHeight: '35px'
          }}
        />
      </div>
      <HookForm.Input
        name="article.cta"
        control={control}
        label="Banner Button Name*"
        placeholder="Fill Name"
        lengthInfoPrefix="Restricted length"
        rules={{
          maxLength: 21
        }}
      />
      <div style={{ margin: '5px 0 21px 0' }}>
        <MediaUploader
          label="Banner Picture (PNG 119x128px)*"
          files={bannerPictureState[0]}
          setFiles={bannerPictureState[1]}
          maxAmount={1}
          accept="image/*"
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <HookForm.Input
          name="article.background_color"
          control={control}
          fieldType={fieldTypes.COLOR}
          width={88}
          label="BG Color (Hex)*"
          placeholder="#000000"
        />
        <PopoverColorPicker
          color={values.article.background_color}
          onChange={onSetBackgroundColor}
          style={{ marginTop: 20 }}
          hasValidColor={Boolean(values.article.background_color) && !errors?.background_color}
        />
      </div>
      <div
        style={{
          fontSize: '20px',
          lineHeight: '24px',
          fontWeight: 400,
          margin: '32px 0 16px'
        }}>
        Post
      </div>
      <MediaUploader
        label="Top Picture (PNG 375x240px)*"
        files={postPictureState[0]}
        setFiles={postPictureState[1]}
        maxAmount={1}
        accept="image/*"
      />
      <div style={{ margin: '16px 0 16px 0' }}>
        <HookForm.ControlledTextarea
          name="article.article_title"
          control={control}
          label="Title*"
          placeholder="Enter Title"
          lengthInfoPrefix="Restricted length"
          rules={{
            maxLength: 77
          }}
          hasSmallLegthTitle
          style={{
            height: '35px',
            minHeight: '35px'
          }}
        />
      </div>
      <HookForm.RichTextEditor
        name="article.description_html"
        control={control}
        scrollingContainer={scrollingContainer}
        renderTooltip={() => <CustomFieldsTooltip />}
        title="Content*"
      />
      <ButtonCTA register={register} control={control} onSetButtonHidden={onSetButtonHidden} setValue={setValue} />
    </>
  );
};
