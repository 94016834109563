import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
// import { Prompt } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import dates from 'constants/dates';
import {
  createPayment,
  createQuotation,
  closeModal,
  openModal,
  toggleInvoiceForm,
  updatePayment,
  updateQuotation
} from 'store/actions';
import { selectServiceInstance } from 'store/reducers/service';

import modalTypes from 'constants/modalTypes';
import { mediaTypes } from 'common/enums/app';
import { invoiceTypes, commissionTypes } from 'common/enums/invoice';
import { INVOICE_TYPE_DEFAULT } from 'common/constants/invoice';
import { fieldTypes } from 'common/enums/form';

import { Line, HookForm, MediaUploader } from 'components';
import ToggleButtonGroup from 'components/HookForm/ToggleButton/ToggleButton';
import Counter from 'components/HookForm/Counter/Counter';
import DatePicker from 'components/HookForm/DatePicker/DatePicker';

import { AutoScroll, IconTooltip, EditableItemsList, InvoiceFee, InvoiceTotal } from './components';
import { getAmountCharge, getPayoutSum, getAmountStripeFee } from './helpers/invoices';
import { getFilesFromMedia, generateAutofillOptionName, generateInitialValues } from '../../helpers';

import { makeDisplayValues } from '../../helpers/transformers';

import {
  Column,
  FlexRow,
  InvoiceAutofillWrapper,
  commissionDividerStyle,
  InvoiceFormWrapper,
  MainHeading,
  SummaryWrapper,
  TipsRow,
  FieldName,
  Heading,
  InvoiceTypeWrapper,
  SectionWrapper,
  MediaBeforeWrapper,
  dividerStyle
} from './styled';

import { inputWrapperStyle } from './commonStyled';

const InvoiceForm = ({
  invoice = null,
  initialValues,
  autocompleteOptions = [],
  isFirstInvoice,
  reward,
  closeModal,
  openModal,
  task,
  depositTotalProviderPayout,
  toggleInvoiceForm,
  createPayment,
  createQuotation,
  updatePayment,
  updateQuotation,
  hasApprovedQuote,
  hasStaticCommissionPercent
}) => {
  const availableInvoiceOptions = Object.values(invoiceTypes)
    .filter(({ value }) => value !== invoiceTypes.DEPOSIT.value)
    .map(({ value, label }) => ({ value, name: label }));

  const [filesBefore, setFilesBefore] = useState(
    getFilesFromMedia({ media: invoice?.media?.filter(({ media_type }) => media_type === mediaTypes.BEFORE) || [] })
  );
  const [filesAfter, setFilesAfter] = useState(
    getFilesFromMedia({ media: invoice?.media?.filter(({ media_type }) => media_type === mediaTypes.AFTER) || [] })
  );

  const [isAutopopulated, setIsAutopopulated] = useState(false);
  const [selectedQuoteAutofill, setSelectedQuoteAutofill] = useState(null);
  const {
    control,
    getValues,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { isValid, isDirty, dirtyFields, errors, touchedFields }
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange'
  });

  const invoice_type = useWatch({ control, name: 'invoice_type' });
  const durationUnit = useWatch({ control, name: 'estimate_duration_unit' });
  const isExpireQuote = !useWatch({ control, name: 'is_do_not_expire' });

  // List of options for pre-fill quote selector
  const filteredOptions =
    invoice_type === invoiceTypes.QUOTATION.value
      ? autocompleteOptions
      : autocompleteOptions?.filter((option) => option.is_accepted);

  const autofillSelectOptions = filteredOptions?.map(
    ({ id, date_created, is_quotation, amount_total_charge, status }) => ({
      id,
      name: generateAutofillOptionName({
        date_created,
        is_quotation,
        status,
        amount_total_charge
      })
    })
  );

  const mergedFiles = {
    ...filesAfter,
    ...filesBefore
  };
  const isEditMediaDirty =
    mergedFiles.length !== (invoice?.media?.length || 0) || mergedFiles.some(({ media_id }) => !media_id);
  const isCreateMediaDirty = mergedFiles.length || invoice_type !== INVOICE_TYPE_DEFAULT;
  const isFormDirty = isDirty || (invoice?.id && isEditMediaDirty) || (!invoice?.id && isCreateMediaDirty);

  const confirmPageClose = (event) => {
    event.preventDefault();
    // browser doesn't use this wording, yet it should be set;
    event.returnValue = 'Are you sure you want to close?';
    return event.returnValue;
  };

  useEffect(() => {
    if (isFormDirty) {
      window.addEventListener('beforeunload', confirmPageClose);
    } else {
      window.removeEventListener('beforeunload', confirmPageClose);
    }
    return () => {
      window.removeEventListener('beforeunload', confirmPageClose);
    };
  }, [isFormDirty]);

  // TODO: (unassigned) add handling navigation
  // useEffect(() => {
  //   const unblock = history.block((location, action) => {
  //     if (isFormDirty) {
  //       // openModal(modalTypes.CANCEL_INVOICE_FORM_CONFIRMATION, {
  //       //   isEditing: Boolean(invoice?.id),
  //       //   onConfirm: unblock
  //       // });

  //       return window.confirm("Navigate?");
  //     }
  //     return true;
  //   });
  //   return () => {
  //     unblock();
  //   };
  // }, [isFormDirty]);

  const handleOpenConfirmationModal = () => {
    const { invoice_type, ...values } = getValues();
    const isFinal = invoice_type === invoiceTypes.FINAL.value;

    const percent_commission = values.percent_commission;
    const is_do_not_expire = values.is_do_not_expire;

    const invoiceBaseFields = {
      amount_tip: isFinal ? values.amount_tip || 0 : '',
      labor: values.labor,
      material: values.material,
      flat_rate: values.flat_rate
    };
    // Display values for margin commission type
    const displayValues = makeDisplayValues({
      isMarginCommission: true,
      percent_commission,
      ...invoiceBaseFields
    });
    const invoiceValues = {
      invoice_type,
      commission_type: commissionTypes.MARGIN,
      percent_commission,
      ...invoiceBaseFields,
      ...displayValues,
      is_moderated: invoice?.is_moderated ?? true,
      is_accepted: invoice?.is_accepted ?? null
    };

    // Call counting utils
    const payoutSum = parseInt(getPayoutSum(invoiceValues) * 100);
    const amountCharge = parseInt(getAmountCharge(invoiceValues) * 100);
    const amountMarkup = amountCharge - payoutSum;
    const amountServiceFee = parseInt(
      getAmountStripeFee({
        percent_stripe_fee: values.percent_stripe_fee,
        ...invoiceValues,
        from: 'InvoiceForm'
      }) * 100
    );
    const amountTotalCharge = amountCharge + amountServiceFee;
    // Data for modal
    const expireDuration = is_do_not_expire ? null : values.expire_duration;
    const datetimeExpire = is_do_not_expire ? null : values.datetime_expire;

    const formattedValues = {
      ...values,
      ...invoiceValues,
      amount_tip: isFinal ? (invoiceValues.amount_tip || 0) * 100 : 0,
      is_final_payment: isFinal,
      amount_charge: amountCharge || 0,
      amount_provider_fee: null,
      amount_platform_fee: amountMarkup || 0,
      amount_stripe_fee: amountServiceFee,
      estimate_duration: values.estimate_duration * values.estimate_duration_unit,
      expire_duration: expireDuration,
      datetime_expire: datetimeExpire ? moment(datetimeExpire)?.format(dates.DATETIME_ISO) : null,
      filesBefore,
      filesAfter
    };
    openModal(modalTypes.CREATE_INVOICE_CONFIRMATION, {
      invoiceAmount: amountTotalCharge,
      invoice: formattedValues,
      onConfirm: onSubmit,
      is_quotation: invoice_type === invoiceTypes.QUOTATION.value,
      task_id: task?.id
    });
  };

  const onSubmit = ({
    invoice: {
      items,
      amount_charge,
      amount_platform_fee,
      amount_provider_fee,
      commission_type,
      percent_commission,
      percent_stripe_fee,
      amount_tip,
      purpose_note,
      is_final_payment,
      filesAfter,
      filesBefore,
      estimate_duration,
      expire_duration,
      percent_deposit,
      datetime_expire,
      is_accepted,
      is_moderated
    },
    task_id,
    is_quotation
  }) => {
    const files = [
      ...filesBefore.map((media) => ({ ...media, media_type: mediaTypes.BEFORE })),
      ...filesAfter.map((media) => ({ ...media, media_type: mediaTypes.AFTER }))
    ];

    const data = {
      id: invoice?.id,
      items: items?.map(({ price, ...item }) => ({ ...item, price: (price || 0) * 100 })) || [],

      amount_tip: amount_tip || 0,

      amount_charge,
      amount_platform_fee,
      commission_type,

      estimate_duration,
      expire_duration,
      datetime_expire,

      purpose_note: purpose_note || '',
      is_final_payment: is_final_payment || false,
      service_request_id: task_id,
      percent_deposit: percent_deposit ? percent_deposit : null,
      percent_commission: (percent_commission || 0) * 100,
      percent_stripe_fee: (percent_stripe_fee || 0) * 100,
      is_moderated
    };
    // case EDIT: QUOTATION
    if (invoice?.id && is_quotation) {
      updateQuotation({ data, files, hasSent: is_moderated && is_accepted === null });
      return;
    }
    // case EDIT: DEPOSIT / PARTIAL PAYMENT / FINAL PAYMENT
    if (invoice?.id && !is_quotation) {
      updatePayment({ data, files, hasModeration: is_moderated });
      return;
    }
    // case CREATE: QUOTATION
    if (is_quotation) {
      createQuotation({ data, files });
      return;
    }
    // case CREATE: DEPOSIT / PARTIAL PAYMENT / FINAL PAYMENT
    // * Creation using autofill selector
    if (isAutopopulated && selectedQuoteAutofill) {
      // from prefill source quote
      const { is_autopopulated } = selectedQuoteAutofill;
      // if Reward (task coupon) was applied OR custom Dobby Discount from autofill quote was applied
      if (!is_autopopulated) {
        // bind payment to its source quotation
        createPayment({ data: { ...data, quotation_id: selectedQuoteAutofill.id }, files });
        // update source quotation with autopopulate & hide modal params
        // also modify amount_charge to 'base' value, so that it is not cumulatively reduced on BE
        updateQuotation({
          data: {
            id: selectedQuoteAutofill.id,
            service_request_id: selectedQuoteAutofill.service_request_id,
            is_autopopulated: true,
            hide_modal: true
          },
          files: selectedQuoteAutofill.media
        });
        return;
      }
    }

    createPayment({ data, files });
  };

  const onCancelConfirmed = () => {
    closeModal(modalTypes.CANCEL_INVOICE_FORM_CONFIRMATION);
    if (!isFirstInvoice) {
      toggleInvoiceForm();
      return;
    }
    setFilesBefore([]);
    setFilesAfter([]);
    reset(initialValues);
  };

  const handleCloseForm = () => {
    if (!isFormDirty) {
      onCancelConfirmed();
      return;
    }
    openModal(modalTypes.CANCEL_INVOICE_FORM_CONFIRMATION, {
      isEditing: Boolean(invoice?.id),
      onConfirm: onCancelConfirmed
    });
  };

  const onInvoiceAutofillChange = (id) => {
    if (id) {
      const selectedInvoice = filteredOptions.find((option) => option?.id === id);
      const filledInvoice = generateInitialValues({ invoice: selectedInvoice });
      setIsAutopopulated(true);
      setSelectedQuoteAutofill(selectedInvoice);

      if (selectedInvoice.is_autopopulated) {
        reset({
          ...filledInvoice,
          datetime_expire: null,
          amount_customer_reward: 0,
          custom_reward_purpose: '',
          reward: {},
          autofill_select: id,
          invoice_type
        });
        return;
      }
      reset({ ...filledInvoice, autofill_select: id, invoice_type, datetime_expire: null });
    }
  };

  const onInvoiceTypeChange = (value) => {
    // reset to initial values here, save radio button state
    reset({ ...initialValues, invoice_type: value });
  };

  const partialEditing =
    invoice && invoice_type !== invoiceTypes.QUOTATION.value && invoice?.amount_provider_payout !== null;
  const commissionType = commissionTypes.MARGIN;
  const defaultExpireDate = invoice?.datetime_expire
    ? moment(invoice.datetime_expire)
    : moment().add(7, 'days').set('hour', 12).set('minute', 0).set('second', 0).set('millisecond', 0);

  const isQuotation = invoice_type === invoiceTypes.QUOTATION.value;
  return (
    <>
      {/* TODO: (unassigned) finish handling navigation, make form provider */}
      {/* <Prompt
        when={true}
        message={() => {
          if (isDirty) {
            return `Cancel invoice ${invoice?.id ? 'editing' : 'creation'}?\nChanges that you made won't be saved.`;
          };
          // to allow a transition
          return true;
        }}
      /> */}
      <AutoScroll />
      <InvoiceFormWrapper>
        <MainHeading>Invoice Type</MainHeading>
        <InvoiceTypeWrapper isHidden={Boolean(invoice?.id)}>
          <HookForm.Radio
            name="invoice_type"
            control={control}
            rules={{ required: true }}
            placeholder="Description"
            options={availableInvoiceOptions}
            onCustomChange={onInvoiceTypeChange}
          />
        </InvoiceTypeWrapper>
        <InvoiceAutofillWrapper width={372} isHidden={!filteredOptions?.length}>
          <HookForm.Select
            label="Autofill by quote"
            name="autofill_select"
            fill
            control={control}
            width={372}
            placeholder="Select quote"
            options={autofillSelectOptions}
            onCustomChange={onInvoiceAutofillChange}
          />
        </InvoiceAutofillWrapper>
        {invoice_type === invoiceTypes.FINAL.value && (
          <MediaBeforeWrapper>
            <MediaUploader label="Attach Media BEFORE (optional)" files={filesBefore} setFiles={setFilesBefore} />
          </MediaBeforeWrapper>
        )}
        <MediaUploader
          label={`Attach Media ${invoice_type === invoiceTypes.FINAL.value ? 'AFTER ' : ''}(optional)`}
          files={filesAfter}
          setFiles={setFilesAfter}
        />
        <SectionWrapper>
          <SummaryWrapper>
            <span>
              <FieldName>Tell us a bit more about the job (optional)</FieldName>

              <IconTooltip
                title={
                  isQuotation ? (
                    <>
                      <div>{`Describe here what will`}</div>
                      <div>{`be done`}</div>
                    </>
                  ) : (
                    <>
                      <div>{`Describe here what has`}</div>
                      <div>{`been done`}</div>
                    </>
                  )
                }
                inline
              />
            </span>

            <HookForm.ControlledTextarea
              name="purpose_note"
              control={control}
              placeholder="E.g., Need to do power wash before the painting, price includes materials"
              textareaConfig={{
                rows: 5
              }}
              fieldType={fieldTypes.INVOICE_TEXT}
              rules={{
                maxLength: 1000,
                maxHeight: 290
              }}
              style={{
                minHeight: '108px'
              }}
            />
          </SummaryWrapper>
          <Line lineStyle={dividerStyle} />
        </SectionWrapper>

        <SectionWrapper>
          <Heading>Job Amount</Heading>
          <Column>
            <EditableItemsList
              arrayFieldName="labor"
              clearErrors={clearErrors}
              control={control}
              setField={setValue}
              setError={setError}
              totalName="Labor (optional)"
            />
          </Column>
          <Column>
            <EditableItemsList
              arrayFieldName="material"
              clearErrors={clearErrors}
              control={control}
              setField={setValue}
              setError={setError}
              priceRequired={false}
              totalName="Materials (optional)"
            />
          </Column>
          <Column>
            <EditableItemsList
              arrayFieldName="flat_rate"
              clearErrors={clearErrors}
              control={control}
              setField={setValue}
              setError={setError}
              totalName="Flat Rate/Hour (optional)"
            />
          </Column>
          <Line lineStyle={commissionDividerStyle} />
        </SectionWrapper>
        {invoice_type === invoiceTypes.QUOTATION.value && (
          <SectionWrapper>
            <Heading>Timeline & Deposit</Heading>
            {invoice?.is_moderated !== true ? (
              <FlexRow>
                <Counter
                  label="Expiration Date of the Quote"
                  name="expire_duration"
                  control={control}
                  defaultValue={invoice?.expire_duration || 7}
                  max={7}
                  rules={{ required: isExpireQuote }}
                  error={errors?.expire_duration}
                />
                <ToggleButtonGroup
                  name="expire_duration_unit"
                  control={control}
                  options={[{ value: 1, name: 'Days' }]}
                  defaultValue={1}
                  isRequired
                  row="true"
                  exclusive
                  error={errors?.expire_duration_unit}
                />
              </FlexRow>
            ) : (
              <FlexRow>
                <DatePicker
                  label="Expiration Date of the Quote"
                  name="datetime_expire"
                  maxDate={moment().add(7, 'days')}
                  control={control}
                  defaultValue={defaultExpireDate}
                  rules={{ required: isExpireQuote }}
                  placeholder="Expiration Date of the Quote"
                  error={errors?.expiration_datetime}
                />
              </FlexRow>
            )}
            <FlexRow>
              <HookForm.Checkbox
                name="is_do_not_expire"
                control={control}
                label="Don't expire the quote"
                defaultValue={false}
                error={errors?.is_do_not_expire}
              />
            </FlexRow>
            <FlexRow>
              <Counter
                label={'Approximated time to complete the job'}
                name="estimate_duration"
                control={control}
                defaultValue={invoice?.estimate_duration ?? 30}
                max={durationUnit && durationUnit === 1 ? 12 : 30}
                rules={{ required: true }}
                error={errors?.estimate_duration}
              />
              <ToggleButtonGroup
                name="estimate_duration_unit"
                control={control}
                options={[
                  { value: 24, name: 'Days' },
                  { value: 1, name: 'Hours' }
                ]}
                defaultValue={invoice?.estimate_duration_unit || 24}
                isRequired
                row="true"
                exclusive
                error={errors?.estimate_duration_unit}
              />
            </FlexRow>
            <ToggleButtonGroup
              name="percent_deposit"
              label="Deposit Payment Request (optional)"
              control={control}
              options={[
                { value: 1000, name: '10%' },
                { value: 2000, name: '20%' },
                { value: 5000, name: '50%' }
              ]}
              defaultValue={invoice?.percent_deposit ?? 5000}
              row="true"
              exclusive
              error={errors?.percent_deposit}
            />
            <FlexRow />
            <Line lineStyle={commissionDividerStyle} />
          </SectionWrapper>
        )}

        <SectionWrapper>
          <Heading>Markup</Heading>
          <InvoiceFee
            commissionType={commissionType}
            control={control}
            hasStaticCommissionPercent={!isQuotation && (hasStaticCommissionPercent || invoice?.commission_value)}
            invoice={invoice}
            invoiceType={invoice_type}
            partialEditing={partialEditing}
            setValue={setValue}
            touchedFields={touchedFields}
            task={task}
          />
          {invoice_type === invoiceTypes.FINAL.value && (
            <TipsRow>
              <FieldName>Tips to Pro (optional)</FieldName>
              <HookForm.Input
                name="amount_tip"
                fieldType={fieldTypes.DOLLAR_CURRENCY}
                control={control}
                readOnly={partialEditing}
                wrapperStyle={inputWrapperStyle}
              />
            </TipsRow>
          )}
          <Line lineStyle={dividerStyle} />
        </SectionWrapper>
        <InvoiceTypeWrapper>
          <Heading>Summary</Heading>
          <InvoiceTotal
            commissionType={commissionTypes.MARGIN}
            control={control}
            invoice={invoice}
            invoiceType={invoice_type}
            task={task}
            onCancelForm={handleCloseForm}
            onSubmitForm={handleOpenConfirmationModal}
            hasSubmitDisabled={!isValid}
            hasCancelButtonDisabled={Boolean(
              isFirstInvoice && !isDirty && invoice_type === INVOICE_TYPE_DEFAULT && !mergedFiles.length
            )}
          />
        </InvoiceTypeWrapper>
      </InvoiceFormWrapper>
    </>
  );
};

InvoiceForm.propTypes = {
  invoice: PropTypes.object
};

const mapStateToProps = (state) => ({
  task: selectServiceInstance(state)
});

const mapDispatchToProps = {
  createPayment,
  createQuotation,
  closeModal,
  openModal,
  toggleInvoiceForm,
  updatePayment,
  updateQuotation
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
