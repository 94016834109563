import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment-timezone';

import {
  useEffect,
  useState,
  useDispatch,
  useForm,
  useWatch,
  useMemo,
  useRouter,
  useSelector,
  useRef,
  useConfirmCloseForm
} from 'hooks';
import { Button } from 'components';
import { openModal, getProviderAreas, closeModal } from 'store/actions';
import { selectCampaignById } from 'store/reducers/campaigns';
import modalTypes from 'constants/modalTypes';
import { createCampaignResolver } from 'common/helpers/formValidators';

import { getDefaultFormValues } from './helpers/form';
import { ArticleSetUp, CampaignPreview, CampaignSetUp } from './components';
import { Step, Footer, ButtonsWrapper, wrapperStyle } from './styled';
import { allInstallBase, cohortTypes } from 'common/enums/campaigns';

const CampaignForm = () => {
  const scrollRef = useRef(null);
  const {
    params: { campaign_id },
    navigate,
    queryParams
  } = useRouter();
  const campaignId = useMemo(() => Number(campaign_id), [campaign_id]);

  const campaign = useSelector((state) => selectCampaignById(state, campaignId));
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (campaignId && !campaign?.id) {
      navigate(-1);
    }
  }, []);

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultFormValues({ campaign }),
    resolver: yupResolver(createCampaignResolver[step] ? createCampaignResolver[step] : createCampaignResolver[1])
  });

  const values = useWatch({ control });

  const dispatch = useDispatch();
  const bannerPictureState = useState(
    campaign?.article?.image_preview_source ? [{ previewUrl: campaign?.article?.image_preview_source }] : []
  );
  const postPictureState = useState(
    campaign?.article?.image_source ? [{ previewUrl: campaign?.article?.image_source }] : []
  );

  const isFormDirty = isDirty || Boolean(postPictureState[0]?.file || bannerPictureState[0]?.file);
  useConfirmCloseForm({ isFormDirty });

  const handleSetUploadedCSVFile = (uploadedFile) => {
    if (uploadedFile) {
      const { file: path, name } = uploadedFile;
      setValue('execution.csv_cohort_name', name, { shouldValidate: true });
      setValue('execution.csv_cohort_path', path, { shouldValidate: true });
    } else {
      setValue('execution.csv_cohort_name', '', { shouldValidate: true });
      setValue('execution.csv_cohort_path', '', { shouldValidate: true });
    }
  };

  const handleSetExecutionDate = (allUsers) => {
    dispatch(
      openModal(modalTypes.EXECUTION_DATE, {
        setDate: (date) => {
          if (allUsers) {
            const dateWithTime = new Date(date);
            dateWithTime.setUTCHours(19);
            const strDate = dateWithTime.toISOString();
            setValue('execution.datetime_execute', strDate, { shouldValidate: true });
          } else {
            setValue('execution.datetime_execute', date, { shouldValidate: true });
          }
          dispatch(closeModal(modalTypes.EXECUTION_DATE));
        },
        date: values.execution?.datetime_execute,
        allUsers
      })
    );
  };

  useEffect(() => {
    dispatch(getProviderAreas());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenConfirmation = (values) => {
    dispatch(
      openModal(modalTypes.CAMPAIGN_CREATION_CONFIRMATION, {
        campaignId,
        values: {
          ...values,
          bannerPicture: bannerPictureState[0],
          postPicture: postPictureState[0]
        }
      })
    );
  };

  const handleNextStep = () => {
    if (step === 1 && !values.article?.service_area_id_list?.length) {
      if (Boolean(campaignId) || values.execution.cohort_type == cohortTypes.ALL_USERS) {
        setValue('article.service_area_id_list', [allInstallBase], { shouldValidate: true });
      } else {
        setValue('article.service_area_id_list', values.execution.service_area_id_list, { shouldValidate: true });
      }
    }
    if (step === 1 && values?.execution.cohort_type == cohortTypes.ALL_USERS) {
      const executionDate = moment(values?.execution.datetime_execute);
      executionDate.set({ hour: 19, minute: 0, second: 0, millisecond: 0 });
      const utcDate = executionDate.utc().toISOString();
      setValue('execution.datetime_execute', utcDate, { shouldValidate: true });
    }
    if (step + 1 === 3) {
      queryParams.setAll({ is_preview: true });
    }
    setStep((step) => step + 1);
  };
  if (step === 3) {
    return (
      <CampaignPreview
        onEditClick={() => {
          setStep(1);
          queryParams.setAll({});
        }}
        onSubmitClick={handleSubmit(handleOpenConfirmation)}
        values={values}
        hasEdit={Boolean(campaignId)}
        bannerPicture={bannerPictureState[0]}
        postPicture={postPictureState[0]}
      />
    );
  }

  const hasUploadedPictures = Boolean((bannerPictureState[0].length && postPictureState[0].length) || step !== 2);

  const handleScrollToEnd = () => {
    if (scrollRef.current) {
      const scrollContainer = scrollRef.current;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  return (
    <div style={wrapperStyle}>
      <div>
        <div
          style={{
            background: '#262E30',
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <div
            style={{
              width: 374,
              background: '#2C3537',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: '20px',
              padding: '4px'
            }}>
            <Step active={step === 1}>Step 1 of 2</Step>
            <Step active={step === 2}>Step 2 of 2</Step>
          </div>
        </div>
        <div
          ref={scrollRef}
          style={{
            overflowY: 'overlay',
            height: 'calc(100vh - 200px)',
            marginBottom: '88px'
          }}>
          <div
            style={{
              width: '372px',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '100px'
            }}>
            {step === 1 && (
              <CampaignSetUp
                control={control}
                values={{
                  path_type: values.path_type,
                  execution: {
                    csv_cohort_name: values.execution?.csv_cohort_name,
                    cohort_type: values.execution?.cohort_type,
                    recipients_list: values.execution?.recipients_list || []
                  }
                }}
                onSetExecutionDate={handleSetExecutionDate}
                onSetIDs={(newValue) => setValue('execution.recipients_list', newValue, { shouldValidate: true })}
                onScrollToEnd={handleScrollToEnd}
                onSetUploadedCSVFile={handleSetUploadedCSVFile}
                onPushTitleChange={({ value, previousValue }) => {
                  const requestSourceName = values.article?.navigation_payload?.request_source_name;
                  if (requestSourceName === previousValue || !requestSourceName) {
                    setValue('article.navigation_payload.request_source_name', value, { shouldValidate: true });
                  }
                }}
              />
            )}
            {step === 2 && (
              <ArticleSetUp
                control={control}
                onSetButtonHidden={(newValue) =>
                  setValue('article.is_button_hidden', newValue, { shouldValidate: true })
                }
                onSetBackgroundColor={(newValue) => {
                  if (newValue === '#NaNNaNNaN') {
                    return;
                  }
                  setValue('article.background_color', newValue, { shouldValidate: true });
                }}
                scrollingContainer={scrollRef?.current}
                register={register}
                bannerPictureState={bannerPictureState}
                postPictureState={postPictureState}
                errors={errors.article}
                setValue={setValue}
                values={{
                  article: {
                    background_color: values.article?.background_color,
                    service_area_id_list: values.article?.service_area_id_list
                  }
                }}
              />
            )}
          </div>
        </div>
        <Footer>
          <ButtonsWrapper>
            <Button
              variant="primary"
              onClick={() => {
                dispatch(openModal(modalTypes.DISCARD_CHANGES, { hasEditing: Boolean(campaignId) }));
              }}>
              Cancel
            </Button>
            <Button disabled={!isValid || !hasUploadedPictures} onClick={handleNextStep}>
              Next
            </Button>
          </ButtonsWrapper>
        </Footer>
      </div>
    </div>
  );
};

export default CampaignForm;
