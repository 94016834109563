import React from 'react';
import { NavLink } from 'react-router-dom';

import { formatPhone, formatters, getDaysCountFromDate } from 'utils';
import { IconButton, UserName, StyledTooltip } from 'components';

import { DataRecord, FieldTitle } from '../../../styled';
import {
  AssignPlaceholder,
  UserNameRow,
  UserPhoneNumber,
  callButtonStyle,
  userNameTextStyle,
  InfoRow,
  TooltipText
} from '../../styled';

export const CustomerInfo = ({
  hasProviderOptionalRow,
  serviceInstance,
  customerInstanceLink,
  handleOpenCallModal
}) => {
  const customer = serviceInstance.user;
  const customerUser = serviceInstance.customer;
  return (
    <DataRecord margin="24px 0">
      <FieldTitle>HO name (ID)</FieldTitle>
      {customer ? (
        <>
          <UserNameRow>
            <NavLink {...customerInstanceLink()}>
              <UserName
                textStyle={userNameTextStyle}
                stage={serviceInstance.user.stage}
                given_name={customer.given_name}
                family_name={customer.family_name}
                id={customer.id}
              />
            </NavLink>
            <IconButton
              style={callButtonStyle}
              iconName="call"
              onClick={() => handleOpenCallModal(serviceInstance.user_id)}
            />
          </UserNameRow>
          <UserPhoneNumber>{formatPhone(customer.phone_number)}</UserPhoneNumber>
          {(hasProviderOptionalRow || Boolean(customer.email)) && (
            <UserPhoneNumber>{customer.email || ' '} </UserPhoneNumber>
          )}
          <InfoRow>
            <StyledTooltip placement="top" arrow title="# of jobs requested">
              <TooltipText>{customerUser.service_count}</TooltipText>
            </StyledTooltip>
            <div>&#9679;</div>
            <StyledTooltip placement="top" arrow title="% of jobs completed">
              <TooltipText>{(customerUser.percent_completed || 0).toFixed()}% </TooltipText>
            </StyledTooltip>
            <div>&#9679;</div>
            <StyledTooltip placement="top" arrow title="# days since the last activity on the app">
              <TooltipText>
                {customerUser.datetime_active ? getDaysCountFromDate(customerUser.datetime_active) : '-'}
              </TooltipText>
            </StyledTooltip>
            <div>&#9679;</div>
            <StyledTooltip placement="top" arrow title="Total DobbyCash balance">
              <TooltipText>{formatters.dollarCurrencyDB(customerUser?.dobby_cash?.amount_current_balance)}</TooltipText>
            </StyledTooltip>
            <div>&#9679;</div>
            <StyledTooltip placement="top" arrow title="Personal funds">
              <TooltipText>{formatters.dollarCurrencyDB(customerUser?.dobby_cash?.amount_paid_balance)}</TooltipText>
            </StyledTooltip>
            <div>&#9679;</div>
            <StyledTooltip placement="top" arrow title="Dobby’s reward">
              <TooltipText>{formatters.dollarCurrencyDB(customerUser?.dobby_cash?.amount_bonus_balance)}</TooltipText>
            </StyledTooltip>
          </InfoRow>
        </>
      ) : (
        <AssignPlaceholder disabled>User was deleted</AssignPlaceholder>
      )}
    </DataRecord>
  );
};
