import styled, { css } from 'styled-components';

export const customFlexRowCSS = css`
  margin-bottom: 0px;
`;

export const FlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.DARK_GREY};

  margin-bottom: 10px;
  ${({ customCSS }) => customCSS}
`;

export const HeaderRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  max-width: 150px;
  width: 150px;
  margin-right: 10px;
`;

export const ValueRow = styled.div`
  flex-basis: ${({ hasTwoColumns }) => (hasTwoColumns ? 150 : 300)}px;
  max-width: 300px;
  display: inline-grid;
  grid-template-columns: 50% 50%;

  color: ${({ valueColor, theme }) => (valueColor ? valueColor : theme.colors.INVOICE_TEXT)};
`;

export const Value = styled.div`
  grid-column: 1;
  text-align: end;
`;

export const SecondaryValue = styled.div`
  grid-column: 2;
  padding-left: 3px;
  text-align: 'start';
`;

export const HeadingTotalCell = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin: 25px 0 10px;
`;

export const MainTotalHeading = styled.span`
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
  word-break: break-all;
  white-space: pre;
`;

export const SummaryHeading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.14;
  margin-top: 11px;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  display: flex;
  align-items: center;
`;

export const Notes = styled.div`
  margin-top: 20px;
`;

export const NoteTitle = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-bottom: 10px;
`;

export const NoteBody = styled.div`
  font-size: 12px;
  line-height: 1.33;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const NoteFile = styled.span`
  font-weight: 500;
  cursor: pointer;
  font-size: 10px;
  line-height: 1.2;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
  color: ${({ theme }) => theme.colors.WHITE};
`;
