import React from 'react';
import { DarkModeToggle, EnvironmentToggle, TestFilterToggle, TimezoneToggle } from './containers';
import { SwitchHolder, SwitchItemHolder, SwitchTestJobsHolder } from './styled';

export const Switches = ({ isLocal }) => {
  return (
    <SwitchHolder>
      <SwitchItemHolder>
        <TimezoneToggle />
      </SwitchItemHolder>
      <SwitchItemHolder>
        <EnvironmentToggle />
      </SwitchItemHolder>
      {isLocal && (
        <SwitchItemHolder>
          <DarkModeToggle />
        </SwitchItemHolder>
      )}
      <SwitchTestJobsHolder>
        <TestFilterToggle />
      </SwitchTestJobsHolder>
    </SwitchHolder>
  );
};
