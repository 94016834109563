import React from 'react';
import { useDispatch, useNavigate, useSelector } from 'hooks';

import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';

import routes from 'constants/routes';
import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';
import { createCampaign, editCampaign } from 'store/actions/campaigns';
import { selectCampaignById, selectCampaignCreationLoading, selectEditCampaignLoading } from 'store/reducers/campaigns';

import { allInstallBase, campaignNavigationTypes, cohortTypes } from 'common/enums/campaigns';
import { DOBBY_APP_DEEP_LINK_PREFIX } from 'common/constants/app';
import moment from 'moment-timezone';

const CampaignCreationConfirmation = () => {
  const modalData = useSelector(selectModalData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campaign = useSelector((state) => selectCampaignById(state, modalData.campaignId));
  const hasLoading = useSelector(modalData?.campaignId ? selectEditCampaignLoading : selectCampaignCreationLoading);

  const closeConfirmationModal = () => {
    dispatch(closeModal(modalTypes.CAMPAIGN_CREATION_CONFIRMATION));
  };
  const onConfirm = async () => {
    const { article, execution, campaign_push, postPicture, bannerPicture } = modalData.values;
    const hasButtonHidden = article.is_button_hidden;
    const navigationType = Number(article.navigation_type);
    const hasHomePageNavigation = !hasButtonHidden && navigationType === campaignNavigationTypes.HOME_PAGE.TYPE;
    const hasCreateJobNavigation = !hasButtonHidden && navigationType === campaignNavigationTypes.JOB_CREATION.TYPE;
    const hasRedirectLinkNavigation = !hasButtonHidden && navigationType === campaignNavigationTypes.REDIRECT_LINK.TYPE;
    const hasDeepLink = article.redirect_link?.startsWith(DOBBY_APP_DEEP_LINK_PREFIX);
    const preparedNavigationType = hasRedirectLinkNavigation
      ? hasDeepLink
        ? campaignNavigationTypes.INTERNAL_LINK.TYPE
        : campaignNavigationTypes.EXTERNAL_LINK.TYPE
      : navigationType;
    const navigationPayload = {
      job_type: article.navigation_payload?.job_type,
      ...(article.navigation_payload?.job_type === null
        ? {}
        : {
            job_category_id: article.navigation_payload?.job_category_id,
            ...(article.navigation_payload?.job_category_id === null
              ? {}
              : {
                  job_description: article.navigation_payload?.job_description
                })
          }),
      request_source_name: article.navigation_payload?.request_source_name
    };
    const preparedData = {
      onSuccessCallback: ({ hasEdit, campaign_id }) => {
        if (campaign_id && !hasEdit) {
          navigate(`${routes.CAMPAIGNS}/${campaign_id}`);
          return;
        }
        navigate(-1);
      },
      postPicture: postPicture[0],
      bannerPicture: bannerPicture[0],
      article: {
        service_areas:
          execution.cohort_type == cohortTypes.ALL_USERS
            ? []
            : article.service_area_id_list?.includes(allInstallBase)
            ? []
            : article.service_area_id_list?.map(({ name }) => name),
        title: article.title,
        background_color: article.background_color,
        article_title: article.article_title,
        description_html: article.description_html,
        is_button_hidden: article.is_button_hidden,
        cta: article.cta,
        ...(!hasButtonHidden
          ? {
              button_title: article.button_title,
              navigation_type: preparedNavigationType
            }
          : {}),
        ...(hasHomePageNavigation
          ? {
              navigation_route: 'Maintenance'
            }
          : {}),
        ...(hasCreateJobNavigation
          ? {
              navigation_payload: navigationPayload
            }
          : {}),
        ...(hasRedirectLinkNavigation && !hasDeepLink
          ? {
              external_link: article.redirect_link
            }
          : {}),
        ...(hasRedirectLinkNavigation && hasDeepLink
          ? {
              internal_link: article.redirect_link
            }
          : {})
      },
      execution: {
        datetime_execute:
          execution.cohort_type === cohortTypes.ALL_USERS
            ? moment(execution.datetime_execute).format('YYYY-MM-DD 19:00:00.00000')
            : execution.datetime_execute,
        ...(execution.cohort_type === cohortTypes.CSV_FILE
          ? {
              csv_cohort_path: execution.csv_cohort_path,
              csv_cohort_name: execution.csv_cohort_name
            }
          : {}),
        ...(execution.cohort_type === cohortTypes.WORKING_AREA || execution.cohort_type == cohortTypes.ALL_USERS
          ? {
              service_areas:
                execution.cohort_type == cohortTypes.ALL_USERS
                  ? []
                  : execution.service_area_id_list?.map(({ name }) => name)
            }
          : {}),
        ...(execution.cohort_type === cohortTypes.RECIPIENTS_LIST
          ? {
              recipients_list: execution.recipients_list?.map((value) => Number(value))
            }
          : {})
      },
      campaign_push: {
        template_title: campaign_push.template_title,
        template_body: campaign_push.template_body
      },
      cohort_type: execution.cohort_type,
      ...(modalData?.campaignId ? { campaign } : {})
    };

    if (modalData.campaignId) {
      dispatch(editCampaign(preparedData));
      return;
    }

    dispatch(createCampaign(preparedData));
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.CAMPAIGN_CREATION_CONFIRMATION}
      hideModal={closeConfirmationModal}
      title={`Confirm campaign ${modalData?.campaignId ? 'editing' : 'creation'}`}
      primaryButton={{
        title: 'Keep Editing',
        onClick: closeConfirmationModal,
        disabled: hasLoading
      }}
      secondaryButton={{
        title: modalData?.campaignId ? 'Confirm' : 'Create',
        onClick: onConfirm,
        loading: hasLoading
      }}
    />
  );
};

export default CampaignCreationConfirmation;
