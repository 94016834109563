import aws4 from 'aws4';
import { Storage, Auth } from 'aws-amplify';

import Api from 'services/ApiService';

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// `wait` milliseconds.
export const debounce = (func, wait) => {
  let timeout;

  // This is the function that is returned and will be executed many times
  // We spread (...args) to capture any number of parameters we want to pass
  return function executedFunction(...args) {
    // The callback function to be executed after
    // the debounce time has elapsed
    const later = () => {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Execute the callback
      func(...args);
    };
    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs Node)
    timeout = setTimeout(later, wait);
  };
};

// Returns a debounced function wrapped into Promise
export const debounceWithPromise = (func, wait) => {
  let timer = null;

  return (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(func(...args)), wait);
    });
  };
};

/*
 *
 * "removeDuplicated" function takes an array of objects with "id" keys or an array of simple items
 * then function removes items whose "id" keys are equal or just equal items
 *
 * */
export const removeDuplicated = (array, key = 'id') => {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (Object.prototype.hasOwnProperty.call(a[i], key)) {
        if (a[i][key] === a[j][key]) a.splice(j--, 1);
      } else {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }
  }
  return a;
};

export const getAWSHeaders = (path, { secretAccessKey, accessKeyId, sessionToken }) => {
  return aws4.sign(
    {
      service: 's3',
      region: 'us-east-1',
      method: 'GET',
      path: path
    },
    {
      secretAccessKey: secretAccessKey,
      accessKeyId: accessKeyId,
      sessionToken: sessionToken
    }
  ).headers;
};

// Convert signle url into S3 config
export const convertUrlToS3Config = (url) => {
  const s3Inner = url.replace('https://s3.amazonaws.com/', '');
  const delimeter = s3Inner.indexOf('/');
  return {
    Bucket: s3Inner.substring(0, delimeter),
    Key: s3Inner.substring(delimeter + 1)
  };
};

// In case of issues with Amplify Storage
// Use of AWS SDK for JS and Auth tokens to get presigned url
// export const getSignedUrl = (url, { secretAccessKey, accessKeyId, sessionToken }) => {
//   const config = convertUrlToS3Config(url);
//   let s3Client = S3({
//     apiVersion: '2006-03-01',
//     params: { Bucket: config.Bucket },
//     signatureVersion: 'v4',
//     region: 'us-east-1',
//     credentials: {
//       secretAccessKey: secretAccessKey,
//       accessKeyId: accessKeyId,
//       sessionToken: sessionToken
//     }
//   });
//
//   return s3Client.getSignedUrl('getObject', config);
// };

export const loomMetadataParser = (loomHtml = '') => {
  // Looking for specific block with page data
  const loomSSRVideoIndex = loomHtml.indexOf('window.loomSSRVideo');
  const loomData = {};

  // In case block was renamed / deleted
  if (loomSSRVideoIndex > -1) {
    // First name of the record
    const nameBlockIndex = loomHtml.indexOf('"name"', loomSSRVideoIndex) + 8;
    loomData.title = loomHtml.slice(nameBlockIndex, loomHtml.indexOf('",', nameBlockIndex));

    // Thumbnails object. Can be parsed as JSON
    const previewIndex = loomHtml.indexOf('"thumbnails"') + 13;
    const thumbnails = JSON.parse(loomHtml.slice(previewIndex, loomHtml.indexOf('},', previewIndex) + 1));
    loomData.images = ['https://cdn.loom.com/' + thumbnails.default];

    // Clean description (generic OG for the site)
    loomData.description = ' ';
  }

  return loomData;
};

export const handleFilePress = async (url) => {
  const config = convertUrlToS3Config(url);
  const signedUrl = await Storage.get(config.Key);

  const win = window.open(signedUrl, '_blank');
  win.focus();
};

export const getImageFromS3 = async (url) => {
  const data = await Auth.currentCredentials();
  const path = url.slice(24);
  const awsHeaders = getAWSHeaders(path, data);

  const response = await Api.get(url, { headers: awsHeaders });

  return response;
};

export const downloadCSV = ({ data, fileName = 'file.csv' }) => {
  const blob = new Blob([new Uint8Array(data)], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const downloadElement = document.createElement('a');
  downloadElement.setAttribute('href', url);
  downloadElement.setAttribute('download', fileName);
  downloadElement.click();

  // Cleaning
  downloadElement.remove();
  window.URL.revokeObjectURL(url);
};
